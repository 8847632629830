<template>
  <div class="selfJudge">
    <div class="container" v-if="showStatus != 3">
      <Practice v-if="isPractice"></Practice>
      <Focus v-if="showStatus == 1"></Focus>
      <div class="main" v-if="showStatus == 2">
        <div class="mirror">
          <b v-if="showWord">{{ original[current].name }}</b>
          <p v-else>这个词语描述了你吗？</p>
        </div>
        <ul class="btns" v-show="!showWord">
          <li v-for="(item, index) in btnList" :key="index">
            <div :class="item.key == active ? 'keyBtn active' : 'keyBtn'">
              {{ item.key }}
            </div>
            <p :class="item.key == active ? 'name activeName' : 'name'">
              {{ item.name }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <BlackScreen v-if="showStatus == 3"></BlackScreen>
  </div>
</template>

<script>
import Practice from '@/components/practice.vue';
import Focus from '@/components/focus.vue';
import BlackScreen from '@/components/blackScreen.vue';
import { saveSubjectiveAns } from '@/api/index';
//数据
let practiceData = [
  { id: 1, mid: 'Neutral_01', type: 'neutral', name: '男生' },
  { id: 2, mid: 'Neutral_02', type: 'neutral', name: '成年人' },
  { id: 3, mid: 'Neutral_03', type: 'neutral', name: '独生子女' },
];

let formalData = [
  { id: 1, mid: 'positive_01', type: 'positive', name: '友好' },
  { id: 2, mid: 'positive_02', type: 'positive', name: '体贴' },
  { id: 3, mid: 'positive_03', type: 'positive', name: '坚强' },
  { id: 4, mid: 'positive_04', type: 'positive', name: '善良' },
  { id: 5, mid: 'positive_05', type: 'positive', name: '认真' },
  { id: 6, mid: 'positive_06', type: 'positive', name: '诚实' },
  { id: 7, mid: 'positive_07', type: 'positive', name: '踏实' },
  { id: 8, mid: 'positive_08', type: 'positive', name: '勤劳' },
  { id: 9, mid: 'positive_09', type: 'positive', name: '自信' },
  { id: 10, mid: 'positive_10', type: 'positive', name: '热情' },
  { id: 11, mid: 'positive_11', type: 'positive', name: '温柔' },
  { id: 12, mid: 'positive_12', type: 'positive', name: '勇敢' },
  { id: 13, mid: 'negative_01', type: 'negative', name: '可恶' },
  { id: 14, mid: 'negative_02', type: 'negative', name: '胆小' },
  { id: 15, mid: 'negative_03', type: 'negative', name: '粗心' },
  { id: 16, mid: 'negative_04', type: 'negative', name: '孤单' },
  { id: 17, mid: 'negative_05', type: 'negative', name: '急躁' },
  { id: 18, mid: 'negative_06', type: 'negative', name: '固执' },
  { id: 19, mid: 'negative_07', type: 'negative', name: '多疑' },
  { id: 20, mid: 'negative_08', type: 'negative', name: '狠心' },
  { id: 21, mid: 'negative_09', type: 'negative', name: '虚荣' },
  { id: 22, mid: 'negative_10', type: 'negative', name: '冒失' },
  { id: 23, mid: 'negative_11', type: 'negative', name: '自私' },
  { id: 24, mid: 'negative_12', type: 'negative', name: '懒惰' },
];

let neutralData = [
  { id: 25, mid: 'neutral_01', type: 'neutral', name: '短发' },
  { id: 26, mid: 'neutral_02', type: 'neutral', name: '戴眼镜' },
  { id: 27, mid: 'neutral_03', type: 'neutral', name: '学生' },
  { id: 28, mid: 'neutral_04', type: 'neutral', name: '中国人' },
];
export default {
  data() {
    return {
      isPractice: true, //是否练习题
      showStatus: 1, //1、光标+集中 2、答题 3、黑屏 4、完成
      current: 0, //当前进度
      randomData: [], //随机数据
      btnList: [
        { key: 'Q', name: '能' },
        { key: 'P', name: '不能' },
      ],
      original: [], //用户原始数据
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      timer: null, //计时器
      startTimeTotal: 0, //起始总时间
      endTimeTotal: 0, //结束总时间
      yesCount: 0, //选是的次数
      blockCurrent: [1, 5, 9, 13, 17, 21, 25], //block结束节点
      totalTime: 0, //用时
      active: '', //高亮显示
      showWord: true,
      timerSubmit1: null,
      timerSubmit2: null,
    };
  },
  components: {
    Practice,
    Focus,
    BlackScreen,
  },
  watch: {
    current() {
      this.progress();
    },
  },
  methods: {
    //初始化数据
    initData() {
      let randomNeutral = this.$util.shuffle(neutralData);
      let positiveArr = [];
      let negativeArr = [];
      this.randomData.forEach((item) => {
        if (item.type == 'positive') {
          positiveArr.push(item);
        }
        if (item.type == 'negative') {
          negativeArr.push(item);
        }
      });
      positiveArr.forEach((item, index) => {
        if ((index + 1) % 4 == 0) {
          this.original = this.original.concat(
            positiveArr.slice(index - 3, index + 1)
          );
          this.original = this.original.concat(
            negativeArr.slice(index - 3, index + 1)
          );
        }
      });
      this.original.unshift(randomNeutral[0], randomNeutral[1]);
      this.original.push(randomNeutral[2], randomNeutral[3]);
      this.progress();
    },
    //用户事件
    choose(keyName) {
      this.endTime = this.$util.getTimestamp();
      if (this.isPractice) {
        this.showWord = true;
        clearTimeout(this.timer1);
        clearTimeout(this.timer2);
        clearTimeout(this.timer3);
        this.progressNext();
      } else {
        if (this.original[this.current].answer) { //已作答
          this.progressNext();
        } else {
          if (keyName == 'Q') {
            this.yesCount++;
          }
          this.showWord = true;
          this.original[this.current].answer = keyName;
          this.original[this.current].time = this.endTime - this.startTime;
          clearTimeout(this.timer1);
          clearTimeout(this.timer2);
          clearTimeout(this.timer3);
          this.progressNext();
        }
      }
    },
    //进行中
    progress() {
      this.timer1 = setTimeout(() => {
        this.showStatus = 2;
        this.showWord = true;
        this.startTime = this.$util.getTimestamp();
      }, 500);
      this.timer2 = setTimeout(() => {
        this.showStatus = 1;
        //   if (!this.original[this.current].answer) {
          //     //用户未操作
          //     this.original[this.current].answer = null;
          //     this.original[this.current].time = null;
          //     this.progressNext();
      }, 1500);
      this.timer3 = setTimeout(() => {
        this.showStatus = 2;
        this.showWord = false;
      }, 2000);
    },
    //进行中
    progressNext() {
      if (this.current >= this.original.length - 1) {
        this.finish();
      } else {
        if (this.blockCurrent.indexOf(this.current) > -1 && !this.isPractice) {
          // setTimeout(() => {
          this.showStatus = 3;
          // }, 100);
          setTimeout(() => {
            this.showStatus = 1;
            this.current++;
            this.active = '';
          }, 6000);
        } else {
          // setTimeout(() => {
          this.showStatus = 1;
          // }, 100);
          setTimeout(() => {
            this.showStatus = 2;
            this.current++;
            this.active = '';
          }, 500);
        }
      }
    },
    finish() {
      this.showStatus = 3;
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        // if (this.totalTime > 30000 - 1000) {
          this.$router.replace({
            path: '/practiceEnd',
            query: {
              source: 'selfJudge',
            },
          });
        // } else {
        //   this.$router.replace({
        //     path: '/relax',
        //     query: {
        //       time: 30000 - this.totalTime,
        //       path: '/practiceEnd?source=selfJudge',
        //     },
        //   });
        // }
      } else {
        //提交数据
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '5',
          quesNum: '5-1',
          totalTime: this.totalTime > 180000 ? 180000 : this.totalTime,
          data1Name: 'yesCount',
          data1: this.yesCount,
          dataJason: JSON.stringify(this.original),
          remark:
            'data1:选择是的次数；mid:编码；type:类型；name：名称；answer：用户答案；time：用时ms；',
        };
        // console.log(query);
        // console.log(this.original);
        saveSubjectiveAns(query)
          .then((res) => {
            if (res.code === 0) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.data.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.data.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.data.isFinished);
              // if (this.totalTime > 180000 - 1000) {
                this.$router.replace({
                  path: '/complete',
                });
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 180000 - this.totalTime,
              //       path: '/complete',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    if (this.$route.query.practice) {
      this.isPractice = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    clearTimeout(this.timerSubmit1);
    clearTimeout(this.timerSubmit2);
    document.onkeyup = '';
  },
  mounted() {
    this.original = [];
    if (this.isPractice) {
      this.randomData = this.$util.shuffle(practiceData);
      this.original = this.randomData;
      this.progress();
      this.timerSubmit1 = setTimeout(() => {
        this.finish();
      }, 30000);
    } else {
      this.randomData = this.$util.shuffle(formalData);
      this.initData();
      this.timerSubmit2 = setTimeout(() => {
        this.finish();
      }, 180000);
    }

    this.startTimeTotal = this.$util.getTimestamp();
    document.onkeyup = (e) => {
      //答题状态时
      if (this.showStatus == 2 && !this.showWord) {
        if (e.keyCode == 80) {
          this.choose('P');
          this.active = 'P';
        }
        if (e.keyCode == 81) {
          this.choose('Q');
          this.active = 'Q';
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .selfJudge {
    display: table-cell;
    vertical-align: middle;
    font-family: 'SYHeiBold';
    .container {
      .main {
        .mirror {
          width: 313px;
          height: 481px;
          margin: 0 auto;
          background-image: url('../../assets/image/self/mirror.png');
          background-repeat: no-repeat;
          background-size: cover;
          color: #b5702d;
          text-align: center;
          p {
            font-size: 18px;
            line-height: 520px;
            text-indent: 20px;
            // line-height: 24px;
            // padding: 164px 0 57px 0;
          }
          b {
            // line-height: 64px;
            font-size: 44px;
            font-weight: 500;
            line-height: 520px;
          }
        }
        .btns {
          width: 310px;
          margin: 0 auto;
          overflow: hidden;
          li {
            width: 100px;
            text-align: center;
            display: inline-block;
            .keyBtn {
              width: 70px;
              height: 70px;
              line-height: 70px;
              margin: 0 auto;
              text-align: center;
              background-image: url('../../assets/image/face/letter.png');
              background-repeat: no-repeat;
              background-size: cover;
              font-size: 34px;
              color: #ffffff;
              font-family: 'SYHeiBold';
            }
            .name {
              margin-top: 20px;
              color: #5f9160;
              font-size: 24px;
              font-family: 'SYHeiBold';
            }
            .active {
              background-image: url('../../assets/image/other/letter_check.png');
            }
            .activeName {
              color: #fa712d;
            }
          }
          li:first-child {
            float: left;
          }
          li:last-child {
            float: right;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .selfJudge {
    display: table-cell;
    vertical-align: middle;
    font-family: 'SYHeiBold';
    .container {
      .main {
        .mirror {
          width: 407px;
          height: 625px;
          margin: 0 auto;
          background-image: url('../../assets/image/self/mirror.png');
          background-repeat: no-repeat;
          background-size: cover;
          color: #b5702d;
          text-align: center;
          p {
            font-size: 23px;
            line-height: 31px;
            padding: 213px 0 74px 0;
            text-indent: 26px;
          }
          b {
            line-height: 676px;
            font-size: 70px;
            font-weight: 500;
          }
        }
        .btns {
          width: 403px;
          margin: 0 auto;
          overflow: hidden;
          li {
            width: 130px;
            text-align: center;
            display: inline-block;
            .keyBtn {
              width: 91px;
              height: 91px;
              line-height: 91px;
              margin: 0 auto;
              text-align: center;
              background-image: url('../../assets/image/face/letter.png');
              background-repeat: no-repeat;
              background-size: cover;
              font-size: 44px;
              color: #ffffff;
              font-family: 'SYHeiBold';
            }
            .name {
              margin-top: 26px;
              color: #5f9160;
              font-size: 31px;
              font-family: 'SYHeiBold';
            }
            .active {
              background-image: url('../../assets/image/other/letter_check.png');
            }
            .activeName {
              color: #fa712d;
            }
          }
          li:first-child {
            float: left;
          }
          li:last-child {
            float: right;
          }
        }
      }
    }
  }
}
</style>
