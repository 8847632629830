<template>
  <!-- 集中焦点 -->
  <div class="focus">
    <img
      :class="'focus' + current"
      src="../assets/image/other/focus.png"
      alt=""
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: '',
    };
  },
  mounted() {
    this.current = this.$util.getStorage('quesNum');
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .focus {
    text-align: center;
    overflow: hidden;
    background-color: #fffef0;
    img {
      width: 80px;
      height: 80px;
      margin: 275px 0;
    }
    .focus2-1 {
      margin-top: 150px;
    }
    .focus2-3 {
      margin-top: 150px;
    }
    .focus5-1 {
      margin-top: 230px;
    }
  }
}
@media screen and (min-width: 1900px) {
  .focus {
    text-align: center;
    overflow: hidden;
    background-color: #fffef0;
    img {
      width: 104px;
      height: 104px;
      margin: 358px 0;
    }
    .focus2-1 {
      margin-top: 195px;
    }
    .focus2-3 {
      margin-top: 195px;
    }
    .focus5-1 {
      margin-top: 299px;
    }
  }
}
</style>
