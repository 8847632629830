<template>
  <!-- 黑屏 -->
  <div class="blackScreen"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.blackScreen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fffef0;
}
</style>
